:root {
  --app-header-height: 50px;
  --sidepanel-project-tabs-height: 56px;
  --top-bar-height: 50px;
}

body {
  margin: 0;
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
#root,
body {
  height: 100vh;
  min-height: calc(100vh);
}

.account-link {
  color: #fff !important;
  text-decoration: none;
}

.cell-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell-logo-name {
  display: flex;
  margin-left: 8px;
  padding: 10px 0px 6px 0px;
}

.hidden {
  display: none;
}

.i {
  font-style: italic;
}

.modal {
  border-radius: 16px;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.modal-small-auto {
  width: 444px;
  height: auto;
  border-radius: 16px;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
  overflow: visible;
}

.no-decoration {
  text-decoration: none;
}

.no-resize {
  resize: none;
}

.no-resize-x {
  resize: vertical;
}

.popover-card {
  padding: 0px;
  width: 150px;
}

.popover {
  padding: 20px;
  width: fit-content;
}

.stretch {
  align-self: stretch;
}

.transparent {
  opacity: 0.5;
}

.w-130 {
  width: 130px;
}

.w-full {
  width: 100%;
}

@media screen and (max-width: 460px) {
  .modal-small-auto {
    width: calc(100vw - 32px);
  }

  .hide-svg>svg {
    display: none;
  }
}

.accountMenu a {
  color: rgba(0, 0, 0, 0.45);
}

.accountMenu a:hover {
  color: rgba(0, 0, 0, 0.85);
}

.hide-location-dropdown {
  display: none;
  background-color: #003c9e;
}

.modal-topology {
  position: relative;
  transition: none;
  transform: none;
}
